<template>
  <router-view />
</template>

<style >
element.style {
  text-decoration: none;
  color: #000; 
  display: none !important;
}
* {
  box-sizing: border-box;
  margin: 0;
}
html,
body {
  margin: 0px !important;
  overflow-x: hidden;
}

@media (max-width: 800px) {
  html,
  body {
    overflow-x: hidden;
  }
}
#app {
  font-family: "Inter Variable", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  font-family: "Inter Variable", sans-serif;
  margin: 0;
  text-align: left;
}
a {
  text-decoration: none;
}
</style>
