<template>
  <div id="fullpage">
    <div class="vertical-scrolling one">
      <h1>Experience Ghana with Us</h1>
      <router-link :to="{ name: 'contact' }">
        Book your unforgettable experience
      </router-link>
    </div>
    <div class="vertical-scrolling two">
      <p>
        Ranging from airport transfers, meet and greet, accomodation, travel and
        tourism to car rentals and Property Acquisition/Management.
      </p>

      <a href="">Explore More</a>
    </div>
    <div class="vertical-scrolling three">
      <p>
        At Okwantufo, Our mission is to provide quality and integrity based
        service solutions to our Diaspora clients which is cost effective, fast,
        reliable.
      </p>
      <a href="">Explore More</a>
    </div>
    <div class="vertical-scrolling four">
      <h2>
        Our services have helped 400,000 Diasporan enjoy the best of services at
        the best rates offered to any diaspora in Ghana.
      </h2>
      <a href="">Explore More</a>
    </div>
  </div>
</template>
<script>
import fullpage from "fullpage.js";
export default {
  mounted() {
    new fullpage("#fullpage", {
      sectionsColor: ["#ff5f45", "#0798ec", "#fc6c7c", "#fec401"],
      sectionSelector: ".vertical-scrolling",
      navigation: true,
      parallax: true,
    });
  },
};
</script>
<style scoped>
.fp-warning,
.fp-watermark {
  display: none !important;
}
h1 {
  color: #fff;
  text-align: center;
  font-size: 7vw;
  margin: 0;
}
h2 {
  color: #fff;
  text-align: center;
  font-size: 5vw;
}
p {
  color: #fff;
  text-align: center;
  font-size: 2.5vw;
  text-align: left;
  width: 900px;
  margin: 3rem;
  font-family: "Inter Variable", sans-serif;
  color: #fff;

  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
div#fp-nav a span {
  background: #fff !important;
}

.one {
  background-image: url(@/assets/homeview/6s.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.one {
  text-align: center;
}
.one a {
  display: inline-flex;
  padding: 1.4375rem 2.8125rem;
  align-items: flex-start;
  gap: 0.625rem;
  margin-top: 30px;
  border-radius: 4px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  font-family: "Inter Variable", sans-serif;
  font-weight: 600;
  line-height: 107.023%;
  letter-spacing: -0.015rem;
  text-decoration: none;
  width: fit-content;
}

.four a {
  display: inline-flex;
  padding: 1.4375rem 2.8125rem;
  align-items: flex-start;
  gap: 0.625rem;
  margin-top: 10px;
  border-radius: 4px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  font-family: "Inter Variable", sans-serif;
  font-weight: 600;
  line-height: 107.023%;
  letter-spacing: -0.015rem;
  text-decoration: none;
  width: fit-content;
}
.two a {
  display: flex;
  padding: 1.4375rem 2.8125rem;
  align-items: flex-start;
  gap: 0.625rem;
  margin-top: 30px;
  margin-left: 3rem;
  border-radius: 4px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  font-family: "Inter Variable", sans-serif;
  font-weight: 600;
  line-height: 107.023%;
  letter-spacing: -0.015rem;
  text-decoration: none;
  width: fit-content;
}
.three a {
  display: flex;
  padding: 1.4375rem 2.8125rem;
  align-items: flex-start;
  gap: 0.625rem;
  margin-top: 30px;
  margin-left: 3rem;
  border-radius: 4px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  font-family: "Inter Variable", sans-serif;
  font-weight: 600;
  line-height: 107.023%;
  letter-spacing: -0.015rem;
  text-decoration: none;
  width: fit-content;
}
.two {
  background-image: url(@/assets/homeview/1s.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.three {
  background-image: url(@/assets/homeview/planes.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.four {
  background-image: url(@/assets/homeview/4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(0.9);
}

@media screen and (max-width: 970px) {
  p {
    font-size: 18px;
    width: auto;
  }
  .one {
    background-position: 73% 100%;
  }
  .two {
    background-position: 42% 100%;
  }
  .four {
    background-position: 42% 100%;
  }
}
</style>
