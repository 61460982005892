import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "@fontsource-variable/inter";
import "remixicon/fonts/remixicon.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import VueGtag from "vue-gtag";
import Swiper from "swiper";
// import Swiper styles
import "swiper/css";
import AOS from "aos";
import "aos/dist/aos.css";

createApp(App)
  .use(VueGtag, {
    config: { id: "G-7ZSCPDNPZR" },
  })
  .mount("#app");

createApp(App).use(AOS.init()).use(router).mount("#app");
