<template>
  <HomeNav />

  <section class="landingPage">
    <div class="landing">
      <div class="landingText">
        <h1
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          Experience Ghana with Us
        </h1>
        <p
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          A full service concierge provider in Ghana which aims at bridging the
          gap between travelers and their home country by offering a wide array
          of quality services to our clients.
        </p>
        <div class="buttons">
          <router-link :to="{ name: 'contact' }">
            Book your unforgettable experience
          </router-link>
        </div>
      </div>
      <!--
      <div class="landingImage" data-aos="fade-left" data-aos-duration="1500">
        <img src="@/assets/homeview/lay.png" alt="" />
      </div>
      -->
    </div>
  </section>

  <section class="about">
    <!----
    <div class="whoweare" data-aos="fade-right" data-aos-duration="900">
      <div class="whotext">
        <h1>Who We Are</h1>
        <p>
          We are a full service concierge provider which aims at bridging the
          gap between travelers and their home country by offering a wide array
          of quality services
        </p>

        <ul>
          <li>
            <img src="@/assets/homeview/correct.png" alt="" />We have a dynamic
            network of agents all over Ghana, ready to deliver our wide range of
            services to clients
          </li>
          <li>
            <img src="@/assets/homeview/correct.png" alt="" />We take the time
            to understand each client in order to deliver a personalized service
            of the highest standard
          </li>
          <li>
            <img src="@/assets/homeview/correct.png" alt="" />We appreciate the
            value of time, thus our services are delivered with speed and
            accuracy
          </li>
          <li>
            <img src="@/assets/homeview/correct.png" alt="" />We understand the
            importance of confidentiality and offer this to all clients
          </li>
        </ul>
        <router-link :to="{ name: 'about' }"> Learn More</router-link>
      </div>
      <div class="whoImage" data-aos="fade-left" data-aos-duration="900">
        <img src="../assets/homeview/ab.png" alt="" />
      </div>
    </div>
  -->
    <div class="tx">
      <p data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
        At Okwantufo, Our mission is to provide quality and integrity based
        service solutions to our Diaspora clients which is cost effective, fast,
        reliable.
      </p>
      <router-link :to="{ name: 'about' }"> Learn More</router-link>
    </div>
  </section>
  <section>
    <div class="two">
      <div class="ts">
        <p
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          Ranging from airport transfers, meet and greet, accommodation, travel
          and tourism to car rentals and Property Acquisition/Management.
        </p>
        <router-link :to="{ name: 'service' }"> Learn More</router-link>
      </div>
    </div>
  </section>
  <WhyChooseUs />
  <section class="values">
    <h1 data-aos="zoom-in-up" data-aos-duration="900">Our Core Values</h1>
    <h3 data-aos="zoom-in-up" data-aos-duration="900">Okwantufo is ELITE</h3>
    <div class="valueCards">
      <div class="valueCard" data-aos="fade-right" data-aos-duration="1500">
        <img src="../assets/values/excellence.svg" alt="" />
        <h2>Excellence</h2>
        <p>
          We strive to deliver a tailored, authentic, consistent and excellent
          service experiences for our clients.
        </p>
      </div>
      <div class="valueCard">
        <img src="../assets/values/legacy.svg" alt="" />
        <h2>Legacy</h2>
        <p>
          We care about and work towards an image and reputation that will
          always precede us.
        </p>
      </div>
      <div class="valueCard" data-aos="fade-left" data-aos-duration="1500">
        <img src="../assets/values/integrity.svg" alt="" />
        <h2>Integrity</h2>
        <p>
          Our ethical principles of trust, sincerity and loyalty defines us and
          our relationship with all our stakeholders.
        </p>
      </div>
      <div class="valueCard" data-aos="fade-right" data-aos-duration="1500">
        <img src="../assets/values/trust.svg" alt="" />
        <h2>Trust</h2>
        <p>
          We are dependable, well equipped and accountable to ensure your mind
          is at peace when working with us.
        </p>
      </div>
      <div class="valueCard">
        <img src="../assets/values/com.svg" alt="" />
        <h2>Effectiveness</h2>
        <p>
          The use of technology enables us to communicate and deliver with speed
          and accuracy on all projects.
        </p>
      </div>
    </div>
  </section>
  <SocialProof />
  <Footer />
</template>

<script>
import Navbar from "@/components/navbar.vue";
import Service from "@/components/service.vue";
import WhyChooseUs from "@/components/whychooseus.vue";
import SocialProof from "@/components/socialproof.vue";
import Footer from "@/components/footer.vue";
import Landing from "@/components/landing.vue";
import HomeNav from "@/components/homenav.vue";

export default {
  name: "HomeView",
  components: {
    Navbar,
    Service,
    WhyChooseUs,
    SocialProof,
    Footer,
    Landing,
    HomeNav,
  },
  methods: {
    track() {
      this.$gtag.pageview({ page_path: "/HomeView" });

      this.$gtag.pageview("/HomeView");

      this.$gtag.pageview(this.$route);
    },
  },
};
</script>

<style scoped>
.buttons {
  gap: 45px;
  margin: 0;
  padding: 0;
}
.landingPage {
  background-image: url(@/assets/homeview/6s.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
.landingPage h1 {
  color: #fff;
  text-align: center;
  font-size: 5vw;
  margin: 0;
}
.landingSection {
  height: 614px;
  display: flex;
  justify-content: center;
  width: 100vw;
  margin-top: -100px;
  border-radius: 0px 0px 500px 0px;
  background-color: #440b67;
  background-image: url("../assets/homeview/tasky.png");
}

.landing {
  display: flex;
  justify-content: center;
  position: absolute;

  align-items: center;
  align-self: center;
  width: 90%;
}

.landingText {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.landingImage {
  width: 100%;
}

.landingImage img {
  width: 600px;
}

.landingText h1 span {
  color: #f24e1e;
}

.landingText p {
  text-align: left;

  font-size: 24px;
  font-family: "Inter Variable", sans-serif;
  color: #fff;
  text-align: center;
  width: 900px;
  align-self: center;
  font-style: normal;
  font-weight: 500;
  line-height: 170%;
}

.landingText a {
  display: inline-flex;
  padding: 1.4375rem 2.8125rem;
  align-items: flex-start;
  gap: 0.625rem;

  border-radius: 4px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  font-family: "Inter Variable", sans-serif;
  font-weight: 600;
  line-height: 107.023%;
  letter-spacing: -0.015rem;
  text-decoration: none;
  width: fit-content;
}

@media (max-width: 1240px) {
  .landingImage img {
    width: 450px;
  }
}

@media (max-width: 1440px) {
}

@media screen and (max-width: 970px) {
  .landingPage {
    background-image: url(@/assets/homeview/6m.jpg);
  }
  .landingPage {
    background-position: 73% 100%;
  }
  .landingText p {
    width: auto;
    font-size: 16px;
  }
  .landingSection {
    height: 962px;
  }
  .landingImage img {
    width: 100%;
  }
  .buttons {
    display: flex;
    gap: 15px;
    flex-direction: column;
  }
  .landing {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    width: 100vw;
    gap: 20px;
    flex-direction: column;
    padding-top: 0px;
  }
  .landingText {
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .landingImage {
    width: 100vw;
    padding: 1rem;
  }

  .landingText a {
    display: inline-flex;
    padding: 8px;
    font-size: 1.5rem;
    width: fit-content;
    align-self: center;
  }
  .landingText {
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 1rem;
  }
  .about p {
    font-size: 4.5vw !important;

    width: auto !important;
    margin: 3rem !important;
  }
  .two p {
    font-size: 4.5vw !important;

    width: auto !important;
    margin: 3rem !important;
  }
}

/*about*/

.about {
  background-image: url(@/assets/homeview/planes.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.about .tx {
  display: flex;
  align-self: start;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100vh;
}
.about p {
  color: #fff;

  text-align: justify;
  font-size: 2.5vw;
  text-align: justify;

  width: 800px;
  margin-left: 3rem !important;
  font-family: "Inter Variable", sans-serif;
  color: #fff;

  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.about h1 {
  text-align: center;
  padding-top: 64px;
  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 57px;
  /* or 158% */

  text-align: center;
  letter-spacing: -0.015em;

  color: #0a2540;
}

.about h1 span {
  color: #f48116;
}
.about a {
  display: inline-flex;
  padding: 1.4375rem 2.8125rem;
  align-items: flex-start;
  gap: 0.625rem;

  border-radius: 4px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  font-family: "Inter Variable", sans-serif;
  font-weight: 600;
  line-height: 107.023%;
  letter-spacing: -0.015rem;
  text-decoration: none;
  width: fit-content;
}

.whoweare {
  display: flex;
  justify-content: center;
  gap: 137px;
  align-items: center;
  align-self: center;
  width: 90%;
  padding: 50px 0px;
}
.whotext ul {
  padding: 0;
  text-align: left;

  flex-direction: column;
}

.whotext li {
  list-style: none;
  align-self: center;
  display: flex;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;
  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin: 0;
  letter-spacing: -0.015em;
  text-align: left;
  color: rgba(66, 84, 102, 0.8);
}
.whotext {
  width: 600px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.whoImage {
  width: 534.74px;
}
.whoImage img {
  width: 534.74px;
}

.whotext h1 {
  font-family: "Inter Variable", sans-serif;
  color: #440b67;
  text-align: left;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150.023%; /* 24.004px */
}
.whotext h3 {
  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0;

  /* or 212% */
  margin: 0;

  text-align: left;
  color: #425466;
}
.whotext p {
  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  /* or 212% */
  margin: 0;
  letter-spacing: -0.015em;
  text-align: left;
  color: rgba(66, 84, 102, 0.8);
}

.whotext a {
  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  display: inline-flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 170px;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #440b67;
  color: #440b67;
}

@media screen and (max-width: 970px) {
  .whoweare[data-v-9ea40744] {
    padding: 50px 0px;
  }
  .whotext {
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 1px;
    padding: 1rem;
  }
  .whoweare {
    width: auto;
    flex-direction: column;
    margin: 1rem;
    gap: 10px;
  }
  .whoImage {
    width: auto;
  }
  .whotext h1 {
    padding: 1rem;
  }
  .whoImage img {
    width: 100vw;
  }
  .whotext h3 {
    padding: 1rem;
  }
  .whotext p {
    padding: 1rem;
  }

  .whotext a {
    padding: 1rem;
  }
  .whotext p {
    font-size: 18px;
    line-height: 1.7;
    letter-spacing: -0.015em;
  }
  .about h1 {
    text-align: center;
    padding-top: 36px;

    margin: 0;
  }
}

/**services */
.two .ts {
  display: flex;
  align-self: start;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 100vh;
}
.two {
  background-image: url(@/assets/homeview/1s.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.two a {
  display: flex;
  padding: 1.4375rem 2.8125rem;
  align-items: flex-start;
  gap: 0.625rem;

  border-radius: 4px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 16px;
  font-family: "Inter Variable", sans-serif;
  font-weight: 600;
  line-height: 107.023%;
  letter-spacing: -0.015rem;
  text-decoration: none;
  width: fit-content;
}
.two p {
  color: #fff;
  text-align: center;
  font-size: 2.5vw;

  width: 800px;
  margin: 3rem;
  font-family: "Inter Variable", sans-serif;
  color: #fff;

  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

/**value */
.values {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0px;
  flex-direction: column;
  background-image: url("../assets/homeview/dimension.png");
}
.values h1 {
  color: #440b67;
  font-family: "Inter Variable", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 28.8px */
  text-align: center;
  margin: 0;
}
.values h3 {
  color: #0a2540;
  font-family: "Inter Variable", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 72px */
  text-align: center;
  margin-top: 0;
}
.valueCards {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.valueCard {
  border-radius: 4px;
  background: #f2eaf6;
  width: 401px;
  height: 279px;
  flex-shrink: 0;
  padding: 3rem 2rem;
}

.valueCard h2 {
  color: #440b67;
  font-family: "Inter Variable", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 36px */
}
.valueCard p {
  color: #440b67;
  font-family: "Inter Variable", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 28.8px */
}

@media (max-width: 970px) {
  .landingText h1 {
    font-size: 4.5vw !important;
  }
  .valueCards {
    grid-template-columns: repeat(2, 1fr);
  }
  .two p {
    font-size: 4.5vw !important;
    width: auto !important;
    margin: 3rem !important;
  }
  .two {
    background-position: 42% 100%;
  }
}
@media (max-width: 750px) {
  .valueCards {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 460px) {
  .valueCards {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
