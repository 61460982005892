<template>
  <div class="whychooseus">
    <h1 class="title">Why Choose Us</h1>
    <p class="subtitle">
      Our goal is to provide quality services at competitive prices while giving
      the best customer service
    </p>
    <div class="slider" x-data="{start: true, end: false}">
      <div
        class="slider__content"
        x-ref="slider"
        x-on:scroll.debounce="$refs.slider.scrollLeft == 0 ? start = true : start = false; Math.abs(($refs.slider.scrollWidth - $refs.slider.offsetWidth) - $refs.slider.scrollLeft) < 5 ? end = true : end = false;"
      >
        <div class="slider__item">
          <img
            class="slider__image"
            src="../assets/choose/personalised.jpg"
            alt="Image"
          />
          <div class="slider__info">
            <h2>Personalized Services</h2>
            <p>
              We treat each client as an individual and tailor our services to
              suit their needs and expectations. This is because we value the
              authenticity of service and we also believe that each person is
              different and so we respect that.
            </p>
          </div>
        </div>
        <div class="slider__item">
          <img
            class="slider__image"
            src="../assets/choose/attention.jpg"
            alt="Image"
          />
          <div class="slider__info">
            <h2>Attention to detail</h2>
            <p>
              Excellence is our hallmark because we believe in the importance of
              being thorough and meticulous with our client’s needs and
              projects. This exemplifies the care we have and show towards our
              clients and all that’s important to them.
            </p>
          </div>
        </div>
        <div class="slider__item">
          <img
            class="slider__image"
            src="../assets/choose/service.jpg"
            alt="Image"
          />
          <div class="slider__info">
            <h2>Service oriented</h2>
            <p>
              Backed with passion, we pride ourselves in rendering services
              tailored at meeting the exact requirements of cherished clients.
              This we achieve by attentively understanding client's needs and
              working within communicated time boundaries.
            </p>
          </div>
        </div>
        <div class="slider__item">
          <img
            class="slider__image"
            src="../assets/choose/legacy.jpg"
            alt="Image"
          />
          <div class="slider__info">
            <h2>Legacy Minded</h2>
            <p>
              We are committed to ensuring that every project handled by us is
              managed with legacy in mind. We help build and translate our
              clients’ dreams into reality.
            </p>
          </div>
        </div>
        <div class="slider__item">
          <img
            class="slider__image"
            src="../assets/choose/network.jpg"
            alt="Image"
          />
          <div class="slider__info">
            <h2>The Right Network</h2>
            <p>
              Our network allows us access to facilities, resources and
              opportunities that enables us to provide the best service at the
              right price for our clients.
            </p>
          </div>
        </div>
        <div class="slider__item">
          <img
            class="slider__image"
            src="../assets/choose/job.jpg"
            alt="Image"
          />
          <div class="slider__info">
            <h2>No Job is Too Small</h2>
            <p>
              We are here for the benefit of our cherished clients. We are
              committed to carrying out every single request with care and
              utmost attention. No matter the size of the request, we are only a
              phone call or email away.
            </p>
          </div>
        </div>
        <div class="slider__item">
          <img
            class="slider__image"
            src="../assets/choose/unsur.jpg"
            alt="Image"
          />
          <div class="slider__info">
            <h2>Unsurpassed Service Providers</h2>
            <p>
              We are affiliated with carefully assessed and selected service
              providers who are highly skilled in their crafts and will offer
              nothing but the best for our clients.
            </p>
          </div>
        </div>
        <div class="slider__item">
          <img
            class="slider__image"
            src="../assets/choose/tech.png"
            alt="Image"
          />
          <div class="slider__info">
            <h2>Technology Inclined</h2>
            <p>
              We apply the best of current technology in our service delivery,
              which means that our clients are always informed, and up-to-date
              on their projects, and enjoy peace of mind which is a key benefit
              to using Okwantufo. Keep track of daily project progress on our
              website with our GPS feature.
            </p>
          </div>
        </div>
      </div>
      <div class="slider__nav">
        <button
          class="slider__nav__button"
          x-on:click="$refs.slider.scrollBy({left: $refs.slider.offsetWidth * -1, behavior: 'smooth'});"
          x-bind:class="start ? '' : 'slider__nav__button--active'"
        >
          Previous
        </button>
        <button
          class="slider__nav__button"
          x-on:click="$refs.slider.scrollBy({left: $refs.slider.offsetWidth, behavior: 'smooth'});"
          x-bind:class="end ? '' : 'slider__nav__button--active'"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>
<script></script>
<style scoped>
.whychooseus {
  background: #440b67;
  background-color: #440b67;
  background-image: url("../assets/homeview/tasky.png");
  padding: 100px 0px;
}

.title {
  font-size: 1rem;
  font-family: "Inter Variable", sans-serif;
  line-height: 1.1;
  font-weight: 300;
  color: #fff;
  margin: 4rem auto 1rem !important;
  width: 85%;
  max-width: 1280px;
}
.subtitle {
  font-size: 40px;
  font-family: "Inter Variable", sans-serif;
  line-height: 1.1;
  font-weight: 300;
  color: #fff;
  margin: 2rem auto 1rem !important;
  width: 65%;
  max-width: 1280px;
}
.slider {
  width: 85%;
  max-width: 1280px;
  margin: 0 auto !important;
}

.slider__content {
  overflow-x: scroll;
  -ms-scroll-snap-type: x mandatory;
  scroll-snap-type: x mandatory;
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
  scrollbar-color: #fff #141e27;
}
.slider__content::-webkit-scrollbar {
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 1rem;
  background: #141e27;
}
.slider__content::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #fff;
}
.slider__content::-webkit-scrollbar-track {
  border-radius: 1rem;
  background: var(--scrollbackground);
}

.slider__item {
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 100%;
  width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  aspect-ratio: 1;
}
@media (min-width: 460px) {
  .slider__item {
    aspect-ratio: 2/3;
    min-width: calc((100% / 2) - 2rem);
  }
}
@media (min-width: 940px) {
  .slider__item {
    min-width: calc((100% / 3) - 1rem);
  }
}

.slider__image {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.slider__info {
  position: relative;
  padding: 4rem 2rem 2rem;
  margin: 1rem;
  border-radius: 4px;
  background: linear-gradient(359deg, #000 0%, rgba(0, 0, 0, 0.5) 100%);
}
.slider__info h2 {
  color: #fff;
  font-family: "Inter Variable", sans-serif;
  line-height: 1.1;
  font-weight: 400;
  font-size: 1.75rem;
  margin: 0;
}
.slider__info p {
  color: #fff;
  font-family: "Inter Variable", sans-serif;
  padding-top: 5px;
  font-weight: 300;
  font-size: 1rem;
  margin: 0;
}
.slider__nav {
  margin: 1rem 0 4rem;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-content: center;
  align-items: center;
  pointer-events: none;
}
@media (min-width: 460px) {
  .slider__nav {
    justify-content: flex-end;
  }
}

.slider__nav__button {
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 2rem;
  background: #fff;
  color: #203239;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  line-height: 1;
  pointer-events: none;
  transition: 0.2s ease-out;
  opacity: 0.25;
}
.slider__nav__button--active {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}
@media (max-width: 970px) {
  .subtitle {
    width: 100%;
    font-size: 4.5vw !important;
  }
}
@media (max-width: 460px) {
  .row {
    flex-direction: column;
  }
}
</style>
