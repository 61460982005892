<template>
  <footer class="new_footer_area bg_color">
    <div class="new_footer_top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div
              class="f_widget company_widget wow fadeInLeft"
              data-wow-delay="0.2s"
              style="
                visibility: visible;
                animation-delay: 0.2s;
                animation-name: fadeInLeft;
              "
            >
              <h3 class="f-title f_600 t_color f_size_18">Get in Touch</h3>
              <p>Be the first to know the goodies of Okwantufo..!</p>
              <form
                action="#"
                class="f_subscribe_two mailchimp"
                method="post"
                novalidate="true"
                _lpchecked="1"
              >
                <input
                  type="text"
                  name="EMAIL"
                  class="form-control memail"
                  placeholder="Email"
                />
                <button class="btn btn_get btn_get_two" type="submit">
                  Subscribe
                </button>
                <p class="mchimp-errmessage" style="display: none"></p>
                <p class="mchimp-sucmessage" style="display: none"></p>
              </form>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="f_widget about-widget pl_70 wow fadeInLeft"
              data-wow-delay="0.4s"
              style="
                visibility: visible;
                animation-delay: 0.4s;
                animation-name: fadeInLeft;
              "
            >
              <h3 class="f-title f_600 t_color f_size_18">Quick links</h3>
              <ul class="list-unstyled f_list">
                <li>
                  <router-link :to="{ name: 'home' }"
                    ><a href="#">Home</a></router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'about' }"
                    ><a href="#">About</a></router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'service' }"
                    ><a href="#">Services</a></router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'service' }"
                    ><a href="#">Meet and Greet</a></router-link
                  >
                </li>

                <li>
                  <router-link :to="{ name: 'contact' }"
                    ><a href="#">Contact</a></router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="f_widget about-widget pl_70 wow fadeInLeft"
              data-wow-delay="0.6s"
              style="
                visibility: visible;
                animation-delay: 0.6s;
                animation-name: fadeInLeft;
              "
            >
              <h3 class="f-title f_600 t_color f_size_18">Help</h3>
              <ul class="list-unstyled f_list">
                <li><a href="#">FAQ</a></li>
                <li><a href="#">Term &amp; conditions</a></li>
                <li><a href="#">Documentation</a></li>
                <li><a href="#">Support Policy</a></li>
                <li><a href="#">Privacy Policy</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div
              class="f_widget social-widget pl_70 wow fadeInLeft"
              data-wow-delay="0.8s"
              style="
                visibility: visible;
                animation-delay: 0.8s;
                animation-name: fadeInLeft;
              "
            >
              <h3 class="f-title f_600 t_color f_size_18">Follow Us</h3>
              <div class="f_social_icon">
                <a
                  href="https://web.facebook.com/Okwantufo"
                  class="fab fa-facebook"
                ></a>
                <a
                  href="https://twitter.com/Okwantufo"
                  class="fab fa-twitter"
                ></a>
                <a
                  href="https://www.instagram.com/okwantufo_/"
                  class="fab fa-instagram"
                ></a>
                <a
                  href="https://www.youtube.com/channel/UCB_z_3onufPcZtAKnz8FldQ"
                  class="fab fa-youtube"
                ></a>
                <a
                  href="https://api.whatsapp.com/send?phone=233599842881"
                  class="fab fa-whatsapp"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer_bg">
        <div class="footer_bg_one"></div>
        <div class="footer_bg_two"></div>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-sm-7">
            <p class="mb-0 f_400">
              Copyright ©2023 All rights reserved | Okwantufo
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
footer {
  background-color: #440b67;
}

.row {
  display: flex;
  justify-content: center;
  align-self: center;
  gap: 100px;
}

li {
  list-style: none;
  font-family: "Inter Variable", sans-serif;
}
ul {
  padding-left: 0;
}
input {
  padding: 1rem;
}
form button {
  padding: 1rem;
}
body {
  background: #fbfbfd;
}

.new_footer_area {
  background: #fbfbfd;
  background-image: url(../assets/homeview/dimension.png);
  font-family: "Inter Variable", sans-serif;
  text-align: left;
}

.f_social_icon svg {
  width: 30px;
  height: 30px;
  display: flex;
}
.new_footer_top {
  padding: 120px 0px 70px;
  position: relative;
  overflow-x: hidden;
}
.new_footer_area .footer_bottom {
  padding-top: 5px;
  padding-bottom: 50px;
}
.footer_bottom {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f88a6;
  padding: 27px 0px;
}
.new_footer_top .company_widget p {
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  color: #6a7695;
  margin-bottom: 20px !important;
}
.new_footer_top .company_widget .f_subscribe_two .btn_get {
  border-width: 1px;
  margin-top: 20px !important;
}
.btn_get_two:hover {
  background: transparent;
  color: #440b67;
}
.btn_get:hover {
  color: #fff;
  background: #6754e2;
  border-color: #6754e2;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:hover,
a:focus,
.btn:hover,
.btn:focus,
button:hover,
button:focus {
  text-decoration: none;
  outline: none;
}

.new_footer_top .f_widget.about-widget .f_list li a:hover {
  color: #5e2ced;
}
.new_footer_top .f_widget.about-widget .f_list li {
  margin-bottom: 11px !important;
}
.f_widget.about-widget .f_list li:last-child {
  margin-bottom: 0px !important;
}
.f_widget.about-widget .f_list li {
  margin-bottom: 15px !important;
}
.f_widget.about-widget .f_list {
  margin-bottom: 0px !important;
}
.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #e2e2eb;
  font-size: 24px;
}
.f_social_icon a {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 45px;
  color: #858da8;
  display: inline-block;
  background: #ebeef5;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.ti-facebook:before {
  content: "\e741";
}
.ti-twitter-alt:before {
  content: "\e74b";
}
.ti-vimeo-alt:before {
  content: "\e74a";
}
.ti-pinterest:before {
  content: "\e731";
}

.btn_get_two {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #440b67;
  border-color: #5e2ced;
  color: #fff;
}

.btn_get_two:hover {
  background: transparent;
  color: #2e0547;
}

.new_footer_top .f_social_icon a:hover {
  background: #5e2ced;
  border-color: #5e2ced;
  color: white;
}
.new_footer_top .f_social_icon a + a {
  margin-left: 4px !important;
}
.new_footer_top .f-title {
  margin-bottom: 30px !important;
  color: #263b5e;
}
.f_600 {
  font-weight: 600;
}
.f_size_18 {
  font-size: 18px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4b505e;
}
.new_footer_top .f_widget.about-widget .f_list li a {
  color: #6a7695;
}

.new_footer_top .footer_bg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 266px;
  background-size: contain;
}

.new_footer_top .footer_bg .footer_bg_one {
  width: 330px;
  height: 105px;
  background-size: 100%;
  position: absolute;
  bottom: 0;
  left: 30%;
  -webkit-animation: myfirst 22s linear infinite;
  animation: myfirst 22s linear infinite;
}

.new_footer_top .footer_bg .footer_bg_two {
  width: 88px;
  height: 100px;
  background-size: 100%;
  bottom: 0;
  left: 38%;
  position: absolute;
  -webkit-animation: myfirst 30s linear infinite;
  animation: myfirst 30s linear infinite;
}

@-moz-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@keyframes myfirst {
  0% {
    left: -25%;
  }
  100% {
    left: 100%;
  }
}

@media (max-width: 970px) {
  .row {
    flex-direction: column;
    padding: 1rem;
  }
}
@media (max-width: 460px) {
  .row {
    flex-direction: column;
  }
}
</style>
