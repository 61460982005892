import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/contact.vue"),
  },
  {
    path: "/travelFocus",
    name: "travelFocus",
    component: () => import("../views/travelFocus.vue"),
  },
  {
    path: "/service",
    name: "service",
    component: () => import("../views/servicepage.vue"),
  },
  {
    path: "/meet&greet",
    name: "meet&greet",
    component: () => import("../views/meet&greet.vue"),
  },
  {
    path: "/car-rentals",
    name: "car-rentals",
    component: () => import("../views/car-rentals.vue"),
  },
  {
    path: "/accommodation",
    name: "accommodation",
    component: () => import("../views/accommodation.vue"),
  },
  {
    path: "/legal",
    name: "legal",
    component: () => import("../views/legal.vue"),
  },
  {
    path: "/business",
    name: "business",
    component: () => import("../views/business.vue"),
  },
  {
    path: "/homecare",
    name: "homecare",
    component: () => import("../views/homecare.vue"),
  },
  {
    path: "/building",
    name: "building",
    component: () => import("../views/building.vue"),
  },
  {
    path: "/technology",
    name: "technology",
    component: () => import("../views/technology.vue"),
  },
  {
    path: "/registrations",
    name: "registrations",
    component: () => import("../views/registrations.vue"),
  },
  {
    path: "/event-planning",
    name: "event-planning",
    component: () => import("../views/event-planning.vue"),
  },
  {
    path: "/property",
    name: "property",
    component: () => import("../views/property.vue"),
  },
  {
    path: "/tourism",
    name: "tourism",
    component: () => import("../views/tourism.vue"),
  },
  {
    path: "/sam-cottage",
    name: "sam-cottage",
    component: () => import("../views/accommodation/sam-cottage.vue"),
  },
  {
    path: "/accra-city",
    name: "accra-city",
    component: () => import("../views/accommodation/accra-city.vue"),
  },
  {
    path: "/airport-west",
    name: "airport-west",
    component: () => import("../views/accommodation/airport-west.vue"),
  },
  {
    path: "/best-western",
    name: "best-western",
    component: () => import("../views/accommodation/best-western.vue"),
  },
  {
    path: "/beulah",
    name: "beulah",
    component: () => import("../views/accommodation/beulah.vue"),
  },
  {
    path: "/beulah",
    name: "beulah",
    component: () => import("../views/accommodation/beulah.vue"),
  },
  {
    path: "/cantoment",
    name: "cantoment",
    component: () => import("../views/accommodation/cantoment.vue"),
  },
  {
    path: "/central-hotel",
    name: "central-hotel",
    component: () => import("../views/accommodation/central-hotel.vue"),
  },
  {
    path: "/east-legon",
    name: "east-legon",
    component: () => import("../views/accommodation/east-legon.vue"),
  },
  {
    path: "/gardens",
    name: "gardens",
    component: () => import("../views/accommodation/gardens.vue"),
  },
  {
    path: "/ibis-styles",
    name: "ibis-styles",
    component: () => import("../views/accommodation/ibis-styles.vue"),
  },
  {
    path: "/labadi-beach",
    name: "labadi-beach",
    component: () => import("../views/accommodation/labadi-beach.vue"),
  },
  {
    path: "/Mekandi",
    name: "Mekandi",
    component: () => import("../views/accommodation/Mekandi.vue"),
  },
  {
    path: "/monticello",
    name: "monticello",
    component: () => import("../views/accommodation/monticello.vue"),
  },
  {
    path: "/movenpick",
    name: "movenpick",
    component: () => import("../views/accommodation/movenpick.vue"),
  },
  {
    path: "/oak",
    name: "oak",
    component: () => import("../views/accommodation/oak.vue"),
  },
  {
    path: "/pramram",
    name: "pramram",
    component: () => import("../views/accommodation/pramram.vue"),
  },
  {
    path: "/regent",
    name: "regent",
    component: () => import("../views/accommodation/regent.vue"),
  },
  {
    path: "/villandro",
    name: "villandro",
    component: () => import("../views/accommodation/villandro.vue"),
  },
  {
    path: "/fiesta",
    name: "fiesta",
    component: () => import("../views/accommodation/fiesta.vue"),
  },
  {
    path: "/graceville",
    name: "graceville",
    component: () => import("../views/accommodation/graceville.vue"),
  },
  {
    path: "/BusiaBeachResort",
    name: "BusiaBeachResort",
    component: () => import("../views/accommodation/BusiaBeachResort.vue"),
  },
  {
    path: "/moleMotel",
    name: "moleMotel",
    component: () => import("../views/accommodation/moleMotel.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
