<template>
  <body class="body">
    <div class="top-img-desk">
      <img
        src="https://raw.githubusercontent.com/Aryan-desale/Social-proof-section./a3e35244a566dae3da8f2f72fd065732de9716ce/images/bg-pattern-top-desktop.svg"
        alt=""
      />
    </div>

    <div class="top-img">
      <img
        src="https://raw.githubusercontent.com/Aryan-desale/Social-proof-section./a3e35244a566dae3da8f2f72fd065732de9716ce/images/bg-pattern-top-mobile.svg"
        alt=""
      />
    </div>

    <div class="Socialproof">
      <div class="proof">
        <div
          class="heading"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <h1>67+ of our Clients love our services.</h1>
        </div>

        <div
          class="para"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <p>
            We only provide great products combined with excellent customer
            service. See what our satisfied customers are saying about our
            services.
          </p>
        </div>
      </div>
      <div class="rating star">
        <div
          class="rate rate1"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1500"
        >
          <span class="image">
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
          </span>
          <h3>5 Stars in Reviews</h3>
        </div>
        <div
          class="rate rate2"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="2000"
        >
          <span class="image">
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
          </span>
          <h3>5 Stars in customer satisfaction</h3>
        </div>

        <div
          class="rate rate3"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="2500"
        >
          <span class="image">
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
            <img class="img" src="../assets/social/icon-star.svg" alt="" />
          </span>
          <h3>5 Stars in excellent customer service</h3>
        </div>
      </div>
    </div>

    <div class="review">
      <div class="rev-box box1" data-aos="fade-right" data-aos-duration="900">
        <div class="avatar">
          <img src="../assets/social/avatar.svg" alt="" />

          <div class="info">
            <h1>Kwabena Osei</h1>

            <h2>Verified</h2>
          </div>
        </div>

        <p>
          "Rented an SUV with a driver with Okwantufo a few days in Ghana. Service was excellent. The Team is very responsive and drivers and very good, experienced, polite and punctual. They have a good knowledge of great places to visit and eat etc. I highly recommend renting a chauffeur driven car from Okwantufo for your vacation in Ghana.!!
Thanks J. !!"
        </p>
      </div>

      <div class="rev-box box2">
        <div class="avatar">
          <img src="../assets/social/avatar.svg" alt="" />

          <div class="info">
            <h1>Kobe Osei</h1>

            <h2>Verified</h2>
          </div>
        </div>

        <p>
          "I used Okwantufo's car rental services during my weeklong trip to Ghana. I was able to accomplish a lot in that short window because of their professionalism, customer service and knowledge of the landscape.  It was a pleasure to work with them. I will work with them again."
        </p>
      </div>

      <div class="rev-box box3" data-aos="fade-left" data-aos-duration="900">
        <div class="avatar">
          <img src="../assets/social/avatar.svg" alt="" />

          <div class="info">
            <h1>Weeks Mensah</h1>

            <h2>Verified</h2>
          </div>
        </div>

        <p>
          "Great service — extremely professional. I will use their service every time I go to Ghana."
        </p>
      </div>
    </div>

    <div class="bottom-img">
      <img
        src="https://raw.githubusercontent.com/Aryan-desale/Social-proof-section./a3e35244a566dae3da8f2f72fd065732de9716ce/images/bg-pattern-bottom-mobile.svg"
        alt="Bottom design"
      />
    </div>

    <div class="bottom-img-desk">
      <img
        src="https://raw.githubusercontent.com/Aryan-desale/Social-proof-section./a3e35244a566dae3da8f2f72fd065732de9716ce/images/bg-pattern-bottom-desktop.svg"
        alt=""
      />
    </div>
  </body>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
  max-width: 100%;

  justify-content: center;
  font-size: 15px;
  font-family: "Inter Variable", sans-serif;
  margin: auto;
  overflow-x: hidden;

  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.top-img-desk {
  display: none;
}

.bottom-img-desk {
  display: none;
}

.body {
  width: 100%;
  max-width: 375px;
  padding: 100px 0px;
}
.Socialproof {
  display: flex;
  justify-content: center;
  gap: 200px;
}
.top-img {
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
  position: absolute;
}

.heading {
  font-size: 1.1rem;
  text-align: center;
  justify-content: center;
  padding: 4em 0 1em 0;
  color: #502050;
}

.para {
  line-height: 1.7;
  margin: auto;
  width: 100%;
  max-width: 310px;
  text-align: center;
  font-size: 0.85rem;
  justify-content: center;
  padding: 0em 1em 1em 1em;
  color: #502050;
  font-weight: 400;
}

.rating {
  margin: auto;
  padding-top: 2em;
  display: grid;
  justify-content: center;
  grid-template-columns: 21em;
  grid-template-rows: 6em 1.3em 6em 1.3em 6em;
  width: 100%;
  max-width: 320px;
  padding-bottom: 2em;
}

.rate {
  width: 100%;
  height: 80px;
  font-size: 0.81rem;
  margin: auto;
  vertical-align: middle;
  align-items: center;
  background-color: #f7f2f7;
  display: inline-block;
  line-height: 1px;
  color: #502050;
  font-weight: 700;
  border-radius: 10px;
  height: 90px;
}

.image > img {
  width: 19px;
  margin-top: 20px;
}

.img + .img {
  padding-left: 0.2em;
}

.image {
  line-height: 60px;
}
.rate1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  text-align: center;
}

.rate2 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;
  text-align: center;
}

.rate3 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 5;
  grid-row-end: 6;
  text-align: center;
}

.rate > h3 {
  vertical-align: middle;
  align-content: center;
}

.review {
  display: grid;
  grid-template-columns: 21em;
  grid-template-rows: 16em 1em 16em 1em 16em;
  justify-content: center;
  margin-bottom: 5em;
}

.rev-box {
  background-color: #502050;
  margin: auto;
  height: max-content;
  padding-bottom: 1rem;
  width: 100%;
  border-radius: 10px;
  color: white;
}

.box1 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.box2 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 4;
}

.box3 {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 5;
  grid-row-end: 6;
}

.avatar {
  display: flex;
  padding: 2em 0 2em 2em !important;
}

.avatar > img {
  width: 100%;
  max-width: 40px;
  border-radius: 50%;
  margin-right: 1.7em !important;
  height: 100%;
}
.info > h1 {
  font-size: 0.9rem;
  line-height: 2;
}

.info > h2 {
  font-size: 0.8rem;
  color: #ee68a4;
  font-weight: 400;
}

.rev-box > p {
  font-size: 0.75rem;
  margin: auto !important;
  width: 247px !important;
  line-height: 2;
  color: #f7f2f7;
  font-weight: 400;
}

.bottom-img {
  width: auto;
  height: auto;
  justify-content: center;
  position: absolute;
  margin-top: -25em !important;
}

.attribution {
  padding-top: 2em;
  font-size: 11px;
  text-align: center;
  padding-bottom: 2em;
  font-weight: 700;
}
.attribution a {
  color: hsl(228, 45%, 44%);
}

@media (min-width: 1000px) {
  body {
    margin: 0;
    width: 100%;
    max-width: 100vw;
  }

  .body {
    width: 100%;
    max-width: 100vw;
  }

  .top-img {
    visibility: hidden;
  }

  .top-img-desk {
    position: absolute;
    display: block;
    margin-left: -5em;
  }

  .heading {
    display: flexbox;
    text-align: left;
    font-size: 1.5rem;
    width: 500px;
    margin: 0;

    line-height: 1;
  }

  .para {
    display: flexbox;
    text-align: left;
    margin: 0;
    font-size: 1rem;
    width: 100%;
    max-width: 500px;
    font-weight: 500;
    color: #937b92;
    padding: 0;
  }

  .rating {
    margin: 0;
    padding: 0;
    position: relative;
    grid-template-columns: 3em 3em 3em 15em 3em 3em;
    grid-template-rows: 3em 1.3em 3em 1.3em 3em;
  }

  .star {
    position: relative;

    padding-top: 100px;
    font-size: 18px;
  }

  .rate {
    margin: 0 !important;
    padding: 0;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .rate1 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
    text-align: center;
  }

  .rate2 {
    grid-column-start: 2;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 4;
    text-align: center;
  }

  .rate3 {
    grid-column-start: 3;
    grid-column-end: 7;
    grid-row-start: 5;
    grid-row-end: 6;
    text-align: center;
  }

  .image > img {
    width: 17px;
    margin-top: 20px !important;
  }

  .rate > h3 {
    margin-left: 2em !important;
    font-size: 0.9rem;
    line-height: 0;
  }

  .image {
    margin-left: 2em !important;
  }

  .review {
    display: grid;
    grid-template-columns: 24em 2em 24em 2em 24em;
    grid-template-rows: 1em 1em 1em 12em 1em 1em 1em;
    margin-top: 5em;
  
  }

  .rev-box {
    background-color: #502050;
    margin: 0;
 
    width: 100%;
    border-radius: 10px;
    color: white;
  }

  .box1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .box2 {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 6;
  }

  .box3 {
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 7;
  }

  .avatar {
    display: flex;
    padding: 2em 0 2em 1.8em;
  }

  .avatar > img {
    width: 100%;
    max-width: 40px;
    border-radius: 50%;
    margin-right: 1.7em;
    height: 100%;
  }
  .info > h1 {
    font-size: 0.9rem;
    line-height: 2;
  }

  .info > h2 {
    font-size: 0.8rem;
    color: #ee68a4;
    font-weight: 400;
    opacity: 0.7;
  }

  .rev-box > p {
    font-size: 0.8rem;
    width: 307px;
  }

  .bottom-img {
    visibility: hidden;
  }

  .bottom-img-desk {
    display: inline-block;
    position: absolute;
    margin-top: -36em;
    margin-left: 17em;
    z-index: -1;
    overflow: hidden;
  }

  .bottom-img-desk > img {
    height: auto;
    width: auto;
  }

  .attribution {
    margin-top: 2em;
    font-size: 13px;
    text-align: center;
    font-weight: 700;
  }
}
@media (max-width: 460px) {
  .Socialproof {
    flex-direction: column;
    gap: 10px;
  }
  .body {
    width: 100%;
    max-width: 100%;
  }
  .review{
    gap: 3rem;
    grid-template-rows: 16em 1em 12em 1em 16em;
}
}
@media (max-width: 940px) {
  .Socialproof {
    flex-direction: column;
  }
  .body {
    width: 100%;
    max-width: 100%;
  }
  .review{
    gap: 3rem;
    grid-template-rows: 16em 1em 12em 1em 16em;
}
}
</style>
