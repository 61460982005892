<template>
  <header class="header">
    <nav class="nav container">
      <div class="nav__data">
        <router-link :to="{ name: 'home' }">
          <a href="#" class="nav__logo">
            <img src="../assets/logo.png" alt="" />
          </a>
        </router-link>
        <div class="nav__toggle" id="nav-toggle">
          <i class="ri-menu-line nav__toggle-menu"></i>
          <i class="ri-close-line nav__toggle-close"></i>
        </div>
      </div>

      <!--=============== NAV MENU ===============-->
      <div class="nav__menu" id="nav-menu">
        <ul class="nav__list">
          <!--=============== DROPDOWN 1 ===============-->
          <!----  <li class="dropdown__item">                      
                                <div class="nav__link dropdown__button">
                                    Services  <i class="ri-arrow-down-s-line dropdown__arrow"></i>
                                </div>
    
                                <div class="dropdown__container">
                                    <div class="dropdown__content">
                                        <div class="dropdown__group">
                                           
        
                                            <span class="dropdown__title">Products</span>
        
                                            <ul class="dropdown__list">
                                              <router-link :to="{name:'home'}">    <li>
                                                    <a href="#" class="dropdown__link">Mobile Messaging (Bulk SMS)</a>
                                                </li>
                                              </router-link>
                                                 <router-link :to="{name:'home'}">
                                                <li>
                                                    <a href="#" class="dropdown__link">Mobile Messaging (Voice Campaign)</a>
                                                </li>
                                              </router-link>
                                                 <router-link :to="{name:'home'}">
                                                <li>
                                                    <a href="#" class="dropdown__link">Kpododo Collect</a>
                                                </li>
                                              </router-link>
                                            </ul>
                                            <br>
                                            <span class="dropdown__title">Services</span>
        
                                            <ul class="dropdown__list">
                                              <router-link :to="{name:'home'}">
                                                <li>
                                                    <a href="#" class="dropdown__link">Cinema Vans</a>
                                                </li>
                                              </router-link>
                                                <router-link :to="{name:'home'}">
                                                <li>
                                                    <a href="#" class="dropdown__link">Video production</a>
                                                </li>
                                              </router-link>
                                                <router-link :to="{name:'home'}">
                                                <li>
                                                    <a href="#" class="dropdown__link">Print & Design</a>
                                                </li>
                                              </router-link>
                                              <router-link :to="{name:'home'}">
                                                <li>
                                                    <a href="#" class="dropdown__link">ICT4D</a>
                                                </li>
                                              </router-link>
                                            </ul>
                                        </div>
        
                                        
                                    </div>
                                </div>
                            </li>-->

          <li class="dropdown__item">
            <router-link :to="{ name: 'service' }">
              <div class="nav__link dropdown__button">Services</div>
            </router-link>
          </li>

          <li class="dropdown__item">
            <router-link :to="{ name: 'meet&greet' }">
              <div class="nav__link dropdown__button">Meet & Greet</div>
            </router-link>
          </li>
          <li class="dropdown__item">
            <router-link :to="{ name: 'tourism' }">
              <div class="nav__link dropdown__button">Travel & Tourism</div>
            </router-link>
          </li>
          <li class="dropdown__item">
            <router-link :to="{ name: 'travelFocus' }">
              <div class="nav__link dropdown__button">Traveler Focus</div>
            </router-link>
          </li>
          <!--=============== DROPDOWN 3 ===============-->

          <li class="dropdown__item">
            <a class="blog" href="https://diasporatoafrica.com/">Blog</a>
          </li>
          <li class="dropdown__item">
            <router-link :to="{ name: 'about' }">
              <div class="nav__link dropdown__button">About</div>
            </router-link>
          </li>

          <router-link :to="{ name: 'contact' }">
            <li class="Talk">
              <a href="#" class="nav__link">Talk To Us</a>
            </li>
          </router-link>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  mounted() {
    const showMenu = (toggleId, navId) => {
      const toggle = document.getElementById(toggleId),
        nav = document.getElementById(navId);

      toggle.addEventListener("click", () => {
        nav.classList.toggle("show-menu");

        toggle.classList.toggle("show-icon");
      });
    };

    showMenu("nav-toggle", "nav-menu");

    const dropdownItems = document.querySelectorAll(".dropdown__item");

    dropdownItems.forEach((item) => {
      const dropdownButton = item.querySelector(".dropdown__button");

      dropdownButton.addEventListener("click", () => {
        const showDropdown = document.querySelector(".show-dropdown");

        toggleItem(item);

        if (showDropdown && showDropdown !== item) {
          toggleItem(showDropdown);
        }
      });
    });

    const toggleItem = (item) => {
      const dropdownContainer = item.querySelector(".dropdown__container");

      if (item.classList.contains("show-dropdown")) {
        dropdownContainer.removeAttribute("style");
        item.classList.remove("show-dropdown");
      } else {
        dropdownContainer.style.height = dropdownContainer.scrollHeight + "px";
        item.classList.add("show-dropdown");
      }
    };

    const mediaQuery = matchMedia("(min-width: 1118px)"),
      dropdownContainer = document.querySelectorAll(".dropdown__container");

    const removeStyle = () => {
      if (mediaQuery.matches) {
        dropdownContainer.forEach((e) => {
          e.removeAttribute("style");
        });

        dropdownItems.forEach((e) => {
          e.classList.remove("show-dropdown");
        });
      }
    };

    addEventListener("resize", removeStyle);
  },
};
</script>

<style scoped>
.blog {
  color: #fff;
}
.nav__logo {
  padding-left: 5rem;
}
.nav__logo img {
  width: 160px;
}
ul {
  list-style: none;
  padding-left: 0;
}

a {
  text-decoration: none;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container {
  max-width: 100vw;
  margin-inline: 1.5rem;
}

/*=============== HEADER ===============*/
.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  background-color: transparent;

  z-index: 100;
}

/*=============== NAV ===============*/
.nav {
  height: 3.5rem;
  font-weight: 600;
}
.nav__data {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav__logo {
  display: inline-flex;
  align-items: center;
  column-gap: 0.25rem;
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  transition: color 0.3s;
}
.nav__logo i {
  font-size: 1.25rem;
}
.nav__logo:hover {
  color: var(--first-color);
}
.nav__toggle {
  position: relative;
  width: 32px;
  height: 32px;
}
.nav__toggle-menu,
.nav__toggle-close {
  font-size: 1.25rem;
  color: #fff;
  position: absolute;
  display: grid;
  place-items: center;
  inset: 0;
  cursor: pointer;
  transition: opacity 0.1s, transform 0.4s;
}
.nav__toggle-close {
  opacity: 0;
}
@media screen and (max-width: 1118px) {
  .nav__menu {
    background-color: #440b67;
    color: #fff;
    position: absolute;
    left: 0;
    top: 2.5rem;
    width: 100%;
    height: calc(100vh - 3.5rem);
    overflow: auto;
    padding-block: 1.5rem 4rem;
    pointer-events: none;
    opacity: 0;
    transition: top 0.4s, opacity 0.3s;
  }
  .nav__menu::-webkit-scrollbar {
    width: 0.5rem;
  }
  .nav__menu::-webkit-scrollbar-thumb {
    background-color: hsl(220, 12%, 70%);
  }
}
.nav__link {
  font-family: "Inter Variable", sans-serif;
  color: #fff;
  font-weight: 600;
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
}
.nav__link:hover {
  background-color: hsl(220, 68%, 97%);
}

/* Show menu */
.show-menu {
  opacity: 1;
  top: 3.5rem;
  pointer-events: initial;
}

/* Show icon */
.show-icon .nav__toggle-menu {
  opacity: 0;
  transform: rotate(90deg);
}

.show-icon .nav__toggle-close {
  opacity: 1;
  transform: rotate(90deg);
}

/*=============== DROPDOWN ===============*/
.dropdown__button {
  cursor: pointer;
}
.dropdown__arrow {
  font-size: 1.5rem;
  font-weight: initial;
  transition: transform 0.4s;
}
.dropdown__content,
.dropdown__group,
.dropdown__list {
  display: grid;
  padding: 0;
}
.dropdown__container {
  background-color: hsl(220, 68%, 97%);
  height: 0;
  overflow: hidden;
  transition: height 0.4s;
}
.dropdown__content {
  row-gap: 1.75rem;
}
.dropdown__group {
  row-gap: 0.5rem;
}
.dropdown__group:first-child {
  margin-top: 1.25rem;
}
.dropdown__group:last-child {
  margin-bottom: 1rem;
}
.dropdown__icon i {
  font-size: 1.25rem;
  color: hsl(220, 68%, 54%);
}
.dropdown__title {
  font-size: 0.813rem;
  font-weight: 500;
  color: hsl(220, 48%, 28%);
  text-align: left;
}
.dropdown__list {
  row-gap: 0.25rem;
}
.dropdown__link {
  font-size: 0.813rem;
  font-weight: 500;
  color: hsl(220, 12%, 45%);
  transition: color 0.3s;
}
.dropdown__link:hover {
  color: var(--title-color);
}

/* Rotate dropdown icon */
.show-dropdown .dropdown__arrow {
  transform: rotate(180deg);
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 300px) {
  .dropdown__group {
    padding-left: 0px;
  }
}
/* For large devices */
@media screen and (min-width: 1118px) {
  /* Nav */
  .nav {
    height: 5rem;
    display: flex;
    justify-content: space-between;

    align-self: center;
  }
  .nav__toggle {
    display: none;
  }
  .nav__list {
    display: flex;
    column-gap: 3rem;

    padding-right: 5rem;
    padding-top: 1rem;

    margin: 0;
  }
  .nav li {
    display: flex;
    align-items: center;
  }
  .nav__link {
    padding: 0;
  }
  .nav__link:hover {
    background-color: initial;
  }
  /* Dropdown */
  .dropdown__button {
    pointer-events: none;
  }
  .dropdown__container {
    height: max-content;
    position: absolute;
    /*left: 0;
        right: 0;*/
    top: 1.5rem;
    background-color: hsl(220, 100%, 99%);
    box-shadow: 0 6px 8px hsla(220, 68%, 12%, 0.05);
    pointer-events: none;
    opacity: 0;
    transition: top 0.4s, opacity 0.3s;
  }
  .dropdown__content {
    grid-template-columns: repeat(1, max-content);
    column-gap: 6rem;
    max-width: 1120px;
    margin-inline: auto;
    padding: 3rem;
  }
  .dropdown__group {
    align-content: baseline;
    row-gap: 1.25rem;
  }
  .dropdown__group:first-child,
  .dropdown__group:last-child {
    margin: 0;
  }
  .dropdown__list {
    row-gap: 0.75rem;
    padding: 0;
  }
  .dropdown__icon {
    width: 60px;
    height: 60px;
    background-color: hsl(220, 68%, 97%);
    border-radius: 50%;
    display: grid;
    place-items: center;
    margin-bottom: 1rem;
  }
  .dropdown__icon i {
    font-size: 2rem;
  }
  .dropdown__title {
    font-size: 0.938rem;
  }
  .dropdown__link {
    font-size: 0.813rem;
  }
  .dropdown__link:hover {
    color: var(--first-color);
  }
  .dropdown__item {
    cursor: pointer;
  }
  .dropdown__item:hover .dropdown__arrow {
    transform: rotate(180deg);
  }
  .dropdown__item:hover > .dropdown__container {
    top: 3.5rem;
    opacity: 1;
    pointer-events: initial;
    cursor: initial;
  }
}
@media screen and (min-width: 1152px) {
  .container {
    margin-inline: auto;
  }
}

@media screen and (max-width: 970px) {
  .dropdown__title {
    font-size: 0.813rem;
    font-weight: 500;
    color: hsl(220, 48%, 28%);
    text-align: center;
  }
}

.Talk {
  background: #440b67;
  border-radius: 8px;
  border: none;
  z-index: 100;
  padding: 12px 29.5px;
}

.Talk a {
  color: white;

  font-family: "Inter Variable", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
@media screen and (max-width: 970px) {
  .nav__logo {
    padding-left: 0;
  }
}
</style>
