<template>
    <div class="service">
        
<div class="container" id="app">


<section class="header" >
  <h2 data-aos="zoom-in-up" data-aos-duration="900">What we offer</h2>
  <p data-aos="zoom-in-up" data-aos-duration="900">We are focused on providing the highest level of service to our customers at
competitive prices. We aim to be a leader in the industry by combining our high quality and high standards with customer satisfaction. We take pride in the fact that we are able to provide a wide range of services customized to meet the needs of every customer.</p>
</section>

<section>
  <div class="card">
    <div class="card-info">
      <h1 id="card-info-title">{{ currentCard.title }}</h1>
      <p id="card-info-desc">{{ currentCard.desc }}</p>
      <router-link :to="{name:'service'}">
        <p>More details</p>
        <svg viewBox="0 0 20 10">
          <line x1="0" y1="5" x2="20" y2="5" />
          <line x1="15" y1="0" x2="20" y2="5" />
          <line x1="15" y1="10" x2="20" y2="5" />
        </svg>
      </router-link>
    </div>
    <div class="card-photo">
      <div id="mask-1" class="mask"></div>
      <div id="mask-2" class="mask"></div>
      <a  @click="nextCard">
        <p>Next</p>
        <svg viewBox="0 0 20 10">
          <line x1="0" y1="5" x2="20" y2="5" />
          <line x1="15" y1="0" x2="20" y2="5" />
          <line x1="15" y1="10" x2="20" y2="5" />
        </svg>
      </a>
      <img :src="currentCard.photo">
    </div>
  </div>
</section>
</div>
    </div>
</template>
<script>
import $ from 'jquery'

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import gsap from 'gsap'

export default {
   
    data() {
    return {
      cards: [
        {
          id: 1,
          title: "Meet and Greet",
          desc:
            "At Okwantufo, we offer our clients an unrivaled hospitality experience upon arrival at kotoka international airport in Ghana. We redefine hospitality in our approach to service. The diasporan is our...",
          photo:require("../assets/service/meet.jpg")
        },
        {
          id: 2,
          title: "Travel & Tourism",
          desc:
            "Explore the beauty of Ghana with Okwantufo. We have affordable packages to make your visits to Ghana memorable through regular tours to historical locations and exotic places of ...",
          photo: require("../assets/service/travel.jpg")
        },
        {
          id: 3,
          title: "Car Rentals",
          desc:
            "We provide you with the best chauffeur for your short or long-term trips. Make a reservation with us for smooth and memorable...",
          photo:require("../assets/service/car.jpg")
        },
        {
          id: 4,
          title: " Accommodation",
          desc:
            "Plan your stay with Okwantufo. We have access to deals and discounts that aren’t available to consumers booking directly. We sometimes have the ability to book in bulk so clients are privy to lower...",
          photo:require("../assets/service/kempiski.jpg")
        }
        ,
        {
          id: 5,
          title: " Accounting & Legal Services",
          desc:
            "With a seasoned team of accounting and legal experts, our range of services includes bookkeeping, tax preparations, legal consultation and documentation, investments, clearance from the port, etc....",
          photo:require("../assets/service/legal.jpg")
        }
        ,
        {
          id: 6,
          title: " Business Management",
          desc:
            "We provide management oversight of your businesses downstream and give you real-time reports on their performance. We work with you to grow your business to its desired expectations. We understand...",
          photo:require("../assets/service/business.jpg")
        }
        ,
        {
          id: 7,
          title: "Home Care Services",
          desc:
            " From performing the little household chores to taking care of your relatives back home, Okwantufo ensures your home remains in good shape while you're away. We have available well-trained nurses...",
          photo:
          require("../assets/service/home.jpg")
        }
        ,
        {
          id: 8,
          title: "   Property Acquisition/Management",
          desc:
            "Buy that dream house or land through Okwantufo or contact us to manage and maintain your properties and estates on your behalf. Our services...",
          photo:
          require("../assets/service/property.jpg")
        }
        ,
        {
          id: 9,
          title: " Building Construction",
          desc:
            " As a result of our partnership with proficient architects, knowledgeable surveyors, and skilled builders we offer services in designing and building solutions to meet each project's specific...",
          photo:
          require("../assets/service/construc.jpg")
        }
        ,
        {
          id: 10,
          title: "   Technological Assistance",
          desc:
            "With our network of programmers and tech agents, we help improve your overall productivity by providing you with modern applications to facilitate business with the global market. We assist you...",
          photo:
            require("../assets/service/technology.jpg")
        }
        ,
        {
          id: 11,
          title: "  Registrations",
          desc:
            " To avoid the stress and rigorous bureaucratic procedures at various registration centers, use Okwantufo. We are linked to the best firms to get your registrations sorted hassle-free. Our wide range...",
          photo:
          require("../assets/service/registration.jpg")
        }
        ,
        {
          id: 12,
          title: "  Event Planning and Management",
          desc:
            " Plan your special events with Okwantufo. We have available special packages to manage all your events and deliver to you the results that you desire. Our services include the...",
          photo:
          require("../assets/service/event.jpg")
        }
      ],
      currentNum: 0
    };
  },
  computed: {
    currentCard() {
      return this.cards[this.currentNum];
    }
  },
  methods: {
    playFoward() {
      let tl = gsap.timeline({
        defaults: {
          duration: 0.7,
          ease: "sine.out"
        },
        onComplete: () => {
          this.playReverse();
          if (this.currentNum >= 11) {
            this.currentNum = 0;
          } else {
            this.currentNum++;
          }
        }
      });
      tl.to("#mask-1", {
        yPercent: 100,
        scaleY: 1.4
      })
        .to(
          "#mask-2",
          {
            yPercent: -100,
            scaleY: 1.4
          },
          "<"
        )
        .to(
          "#card-info-title",
          {
            clipPath: `polygon(0 0, 100% 0, 100% 0%, 0% 0%)`
          },
          "<0.4"
        )
        .to(
          "#card-info-desc",
          {
            clipPath: `polygon(0 0, 100% 0, 100% 0%, 0% 0%)`
          },
          "<0.3"
        );
    },
    playReverse() {
      let tl = gsap.timeline({
        defaults: {
          duration: 0.7,
          ease: "sine.in"
        }
      });
      tl.to("#mask-1", {
        yPercent: -100,
        scaleY: 1.4
      })
        .to(
          "#mask-2",
          {
            yPercent: 100,
            scaleY: 1.4
          },
          "<"
        )
        .to(
          "#card-info-title",
          {
            clipPath: `polygon(0 0, 100% 0, 100% 100%, 0% 100%)`
          },
          "<0.2"
        )
        .to(
          "#card-info-desc",
          {
            clipPath: `polygon(0 0, 100% 0, 100% 100%, 0% 100%)`
          },
          "<0.3"
        );
    },
    nextCard() {
      this.playFoward();
    }
}}

</script>
<style scoped>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --green: #ecfaec;
  --dark-green: #166639;
  --white: #ffffff;
  --black: #202020;
}

.service {
  min-height: 100vh;
  display: grid;
  place-items: center;
 
  background-color: var(--green);

  color: var(--black);
}
.service{
    display: flex;
    align-items: center;
    align-self: center;
    flex-direction: column;
    padding: 100px 0px;
    background-color:  #440B67;
    background-image: url("../assets/homeview/shattered.png");
   
}
ul {
  list-style: none;
}

.header h2{
  font-family: 'Inter Variable', sans-serif;
    color: #ffffff;
    text-align: left;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.023%;

}
.header{
display: flex;
align-items: center;
align-self: center;
flex-direction: column;


}
.header p{
font-size: 16px;
color:  #ffffff;
width: 600px;
text-align: center;
margin: 1rem;
}
a {
  text-decoration: none;
  color: inherit;
}

.container {
  width: 100%;
  max-width: 900px;
}

header {
  padding: 2em 1em;
  display: grid;
  grid-template-columns: 100px 1fr;
  align-items: center;
}
header h1 {
  font-family: "Allura", serif;
  color: var(--dark-green);
}
header ul {
  display: flex;
}
header li {
  margin-right: 3em;
  font-size: 0.85rem;
  font-weight: bold;
}

section {
  width: 100%;
  padding: 1em;
}

section h4 {
  opacity: 0.8;
}

li.active {
  color: var(--dark-green);
  border-bottom: 1px solid var(--dark-green);
}

.card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  background-color: #ffffff;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.card-info {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  padding: 1em 2em;
}
.card-info h1 {
  font-family: 'Inter Variable', sans-serif;
  font-size: 30px;
  color: var(--dark-green);
  align-self: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}
.card-info p {
  font-size: 0.8rem;
  font-weight: bold;
  align-self: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  line-height: 22px;
}
.card-info a {
  display: grid;
  grid-template-columns: max-content 20px;
  align-items: center;
  column-gap: 7px;
  color: var(--dark-green);
  font-size: 0.85rem;
  font-weight: bold;
  align-self: center;
  justify-self: flex-end;
  transform: translateX(0px);
  transition: transform 0.3s 0.1s ease-out;
}
.card-info a:hover {
  transform: translateX(5px);
}
.card-info a:hover svg {
  transform: translateX(5px);
}
.card-info svg {
  width: 20px;
  height: 10px;
  transform: translateX(0px);
  transition: transform 0.3s ease-out;
}
.card-info svg line {
  stroke: var(--dark-green);
}

.card-photo {
  width: 450px;
  object-fit: cover;
  position: relative;
  overflow: hidden;
}
.card-photo img {
  display: block;
  max-width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.card-photo a {
  display: grid;
  grid-template-columns: max-content 20px;
  cursor: pointer;
  align-items: center;
  column-gap: 7px;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  align-self: flex-start;
  transform: translateX(0px);
  transition: transform 0.3s 0.1s ease-out;
  position: absolute;
  z-index: 10;
  bottom: 5%;
  right: 8%;
}
.card-photo a:hover {
  transform: translateX(5px);
}
.card-photo a:hover svg {
  transform: translateX(5px);
}
.card-photo svg {
  width: 20px;
  height: 10px;
  transform: translateX(0px);
  transition: transform 0.3s ease-out;
}
.card-photo svg line {
  stroke: var(--white);
  stroke-width: 2;
}
.card-photo .mask {
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  z-index: 5;
  background-color: var(--white);
}
#mask-1 {
  left: 0;
  transform: translateY(-100%);
}
#mask-2 {
  right: 0;
  transform: translateY(100%);
}

@media (max-width: 600px) {
  .card {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .header p{
    width: auto;
}
  .card-info {
    order: 2;
    height: 400px;
  }
  .card-photo {
    width: 100%;
    order: 1;
  }

  .card-photo img {
    width: 100%;
  }

  .card-photo .mask {
    height: 100%;
  }
}

@media (max-width: 500px) {
  header {
    padding: 1em;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }
  header ul {
    flex-direction: column;
  }
  header li {
    width: min-content;
    margin-right: 0;
  }
  .card-info h1 {
    font-size: 3rem;
  }
}
</style>